import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import './styles.css'
import React, { useEffect } from 'react'
import {
  trackGAEvent,
  trackSubscroptionCompletedEvent
} from '~/client/tools/googleAnalytics'
import { ADJUST_EVENTS, trackAdjustEvent } from '~/client/tools/adjustInIt'
import { CLEVERTAP_EVENTS, trackCleverTapEvent } from '~/client/tools/clevertap'
import {
  FB_EVENTS,
  pixelEvents,
  trackFbEvent,
  trackFbPixel
} from '~/client/tools/fbInit'

const ThankYou = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, _] = useSearchParams()

  const fromSSL = searchParams.get('ssl')

  useEffect(() => {
    trackCleverTapEvent(CLEVERTAP_EVENTS.SUBSCRIPTION_COMPLETED, {})

    trackGAEvent('Subscription_Completed', {})
    trackSubscroptionCompletedEvent('Subscription_Completed', {})
    trackFbEvent(FB_EVENTS.SUBSCRIPTION_COMPLETED, {})

    trackFbPixel(pixelEvents.SUBSCRIPTION_COMPLETED, {})

    trackAdjustEvent(ADJUST_EVENTS.SUBSCRIPTION_COMPLETED, {})

    const script = document.createElement('script')
    script.src = 'https://www.dwin1.com/61801.js'
    script.type = 'text/javascript'
    script.defer = true
    // Append the script to the document's head
    document.head.appendChild(script)
    // Clean up the script when the component unmounts

    const redirectTimeout = setTimeout(() => {
      navigate('/')
    }, 10000)
    return () => {
      document.head.removeChild(script)
      clearTimeout(redirectTimeout)
    }
  }, [])

  if (!location?.state?.fromPaymentGateway || !fromSSL) {
    return <Navigate to="/" replace />
  }

  return (
    <main className="thank-you-container">
      <h1>Thank you for choosing hoichoi!</h1>
      <p style={{ textAlign: 'center' }}>
        Your subscription is now active. Sit back, relax and let the hoichoi
        begin.
      </p>
      <br />
      <p>Happy Binging 🍿🎬</p>
      <button
        className="primary-button navbar-home-btn"
        onClick={() => navigate('/')}
      >
        Home
      </button>
    </main>
  )
}

export default ThankYou
